<template>
  <div class="org-machine-list">
    <h2 class="title">机床管理系统</h2>
    <div class="tree-container">
      <tree-node
          v-for="org in organizations"
          :key="org.id"
          :org="org"
      ></tree-node>
    </div>
  </div>
</template>

<script>
import TreeNode from './wxTreeNode.vue';
import { post } from '@/apis/restUtils';

export default {
  components: {
    TreeNode
  },
  data() {
    return {
      organizations: [
        // 模拟数据，实际应从后端接口获取
        {
          id: 0,
          name: 'Org 1',
          status: 'working',
          children: [
            {
              id: '1',
              name: 'Org 1',
              status: 'working',
              children: [
                {id: '2', name: 'Org 1-1', status: 'working'},
                {id: '3', name: 'Org 1-2', status: 'warning'}
              ]
            },
            {
              id: '4',
              name: 'Org 2',
              status: 'working',
              children: [
                {id: '5', name: 'Org 2-1', status: 'idle'},
                {id: '6', name: 'Org 2-2', status: 'offline'}
              ]
            }
          ]
        },
      ]
    };
  },
  mounted() {
    this.fetchOrganizations(); // 组件挂载时获取数据
  },
  methods: {
    async fetchOrganizations() {
      try {
        await post('/organization/orgTree', {
          // 在此处添加 POST 请求所需的参数，例如：
          // userId: this.$store.state.userId
        }, this.fetchOrganizationsConsumer);

      } catch (error) {
        console.error('获取 organizations 数据失败:', error);
      }
    },
    fetchOrganizationsConsumer(response) {
      this.organizations = response.data; // 将后端返回的数据赋值
    },
  }
};
</script>

<style scoped>
.org-machine-list {
  padding: 20px;
}

.title {
  margin-bottom: 30px; /* 拉开标题与 TreeNode 的距离 */
}

.tree-container {
  display: flex;
  flex-direction: column;
  gap: 10px; /* 增加顶级节点之间的间距 */
}
</style>