<template>
  <div :class="['tree-node', isLeaf ? 'leafnode' : 'node']">
    <div class="node-content" @click="toggleExpand">
      <!-- node 显示展开/折叠箭头 -->
      <span v-if="!isLeaf">{{ isExpanded ? '▼' : '▶' }}</span>
      <!-- leafnode 显示状态圆点 -->
      <span v-if="isLeaf" :class="['status-dot', getStatusClass(org.status)]"></span>
      <span class="node-name">{{ org.name }}</span>
      <span class="more" @click.stop="goToDetail(org.id)">⋮</span>
    </div>
    <div v-if="isExpanded && org.children && org.children.length > 0" class="children">
      <tree-node
          v-for="child in org.children"
          :key="child.id"
          :org="child"
      ></tree-node>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TreeNode',
  props: {
    org: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isExpanded: false // 默认折叠
    };
  },
  computed: {
    isLeaf() {
      // 判断是否为叶子节点
      // return !this.org.children || this.org.children.length === 0;
      return this.org.type === 'LEAF';
    }
  },
  mounted() {
    console.log("Org:", this.org.name, "Children:", this.org.children);
  },
  methods: {
    toggleExpand() {
      if (!this.isLeaf) { // 仅对非叶子节点切换展开状态
        console.log("Toggle for", this.org.name, "from", this.isExpanded, "to", !this.isExpanded);
        this.isExpanded = !this.isExpanded;
      }
    },
    goToDetail(id) {
      this.$router.push({ name: 'wxMachineInfo', params: { id } });
    },
    getStatusClass(status) {
      switch (status) {
        case 'working':
          return 'working';
        case 'idle':
          return 'idle';
        case 'warning':
          return 'warning';
        case 'offline':
          return 'offline'; // 新增 offline 状态
        default:
          return '';
      }
    }
  }
};
</script>

<style scoped>
.tree-node {
  margin-left: 20px; /* 子节点缩进 */
  margin-bottom: 10px; /* 节点之间的垂直间距 */
}

.node {
  //background-color: #e6f0fa; /* node 的淡蓝色背景 */
  padding: 5px;
  border-radius: 4px;
}

.leafnode {
  background-color: #fffbe6; /* leafnode 的淡黄色背景 */
  padding: 5px;
  border-radius: 4px;
}

.node-content {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.status-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 10px;
}

.working {
  background-color: green;
}

.idle {
  background-color: yellow;
}

.warning {
  background-color: red;
}

.offline {
  background-color: #808080; /* offline 状态的灰色 */
}

.node-name {
  flex: 1; /* 名称占满剩余空间 */
}

.more {
  margin-left: 10px;
  cursor: pointer;
  font-size: 20px; /* 增大字体大小 */
  font-weight: bold; /* 加粗 */
  padding: 2px 5px; /* 添加内边距 */
  background-color: #f0f0f0; /* 浅灰色背景 */
  border-radius: 3px; /* 圆角 */
}

.more:hover {
  background-color: #e0e0e0; /* 悬停时颜色变深 */
}

.children {
  margin-left: 5px;
  display: flex;
  flex-direction: column;
  gap: 1px; /* 子节点之间的垂直间距 */
}
</style>